html, body, #root {
	height: 100%;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}

body {
  font-family: 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	font-family: "Helvetica Neue", Helvetica, sans-serif;
	font-size: 14px;
  padding: 10.5px 14px;
  width: 100%;
  color: #000000;
	border: 1px solid #c4c4c4;
  border-radius: 4px;
}

.StripeElement::placeholder {
  color: #585858;
}

.InputElement.is-empty.Input--empty::placeholder {
  color: #000;
}

.mainSchedulePageAiBox > div > div {
    background-color: #f7f8fa;
    padding: 8px;
    border: 1px dashed  rgba(0, 0, 0, 0.48);
}

